<template>
	<main class="flex flex-col gap-5 bg-yellow w-full py-10">
		<h1 class="text-2xl md:text-4xl uppercase text-center font-bold">
			Aprobar Academia
		</h1>
		<section class="grid grid-cols-3 gap-5 max-w-screen-lg mx-auto w-11/12">
			<article
				v-for="course in courses"
				:key="course.doc"
				class="bg-white rounded-lg drop-shadow px-5 py-4 flex flex-col gap-3"
			>
				<header class="flex justify-between items-center w-full">
					<small class="font-bold">
						{{ new Date(course.completedAt).toLocaleDateString() }} -
						{{ new Date(course.completedAt).toLocaleTimeString() }}
					</small>
					<small class="font-bold">
						Curso <span class="uppercase">{{ course.period }}</span>
					</small>
				</header>
				<div class="flex flex-col gap-y-2">
					<div class="">
						<strong>Curso:</strong>
						OPTIMUN
					</div>
					<div class="">
						<strong>Respuestas:</strong>
						{{ extractQuestionLength(course.answers) }}
					</div>
					<div class="">
						<strong>UID: </strong>
						<small>{{ course.uid }}</small>
					</div>
				</div>
				<button
					class="bg-yellow rounded-full px-4 mt-2 mx-auto py-2 text-black font-bold drop-shadow-sm"
					@click="() => openModal(course)"
				>
					Revisar
				</button>
			</article>
		</section>
		<Loader :isLoading="isLoading" />

		<aside class="modal" v-if="isOpen">
			<aside
				class="bg-white max-w-screen w-10/12 rounded-2xl py-10 flex flex-col gap-3 overflow-y-auto"
				style="max-height: 85vh"
			>
				<header class="flex flex-col px-5">
					<button
						class="font-bold ml-auto pr-6 text-xl"
						@click="isOpen = false"
					>
						x
					</button>
					<h3 class="text-center uppercase font-bold text-xl mb-4">
						Validar respuestas
					</h3>
				</header>
				<aside
					class="flex flex-col max-w-screen-modal mx-auto w-10/12"
					style="gap: 2rem"
				>
					<div
						class="grid grid-cols-2 mx-auto w-full items-center gap-x-"
						style="gap: 5rem"
						v-for="question in course.answers"
						:key="question.questionId"
						v-show="question.optionsSelected[0].optionId === ''"
					>
						<div style="text-wrap: pretty">
							{{ question.optionsSelected[0].text }}
						</div>
						<div class="flex flex-col gap-2">
							<h4 class="text-center">¿Es válida esta respuesta?</h4>
							<div class="flex gap-5 justify-center">
								<label class="flex gap-2 items-center justify-center">
									<input
										type="radio"
										:name="'respuesta-' + question.questionId"
										v-model="question.isValid"
										:value="true"
										@change="question.optionsSelected[0].isValid = true"
									/>
									Si
								</label>
								<label class="flex gap-2 items-center justify-center">
									<input
										type="radio"
										:name="'respuesta-' + question.questionId"
										v-model="question.isValid"
										@change="question.optionsSelected[0].isValid = false"
										:value="false"
									/>
									No
								</label>
							</div>
						</div>
					</div>
					<button
						class="bg-yellow rounded-full px-4 mt-2 mx-auto py-2 text-black font-bold drop-shadow-sm"
						@click="saveCourse"
					>
						Calificar respuestas
					</button>
				</aside>
			</aside>
		</aside>
	</main>
</template>

<script>
import { db } from "../../firebase";
import Loader from "../../components/Loader.vue";
import firebase from "firebase/app";

export default {
	components: { Loader },
	data: () => ({
		isLoading: false,
		courses: [],
		isOpen: false,
		course: null,
	}),
	async mounted() {
		await this.getCourse();
	},
	methods: {
		async getCourse() {
			try {
				this.isLoading = true;
				const snap = await db
					.collection("certifications")
					.where("period", "in", ["p05", "p06"])
					.where("year", "==", "2024")
					.where("isReviewed", "==", false)
					.orderBy("completedAt", "asc")
					.get();

				this.courses = snap.docs.map((doc) => ({ doc: doc.id, ...doc.data() }));
			} catch (error) {
				console.log(error);
			} finally {
				this.isLoading = false;
			}
		},
		extractQuestionLength(questions) {
			return questions
				.flatMap((question) => question.optionsSelected)
				.filter((option) => option.optionId == "").length;
		},
		openModal(course) {
			this.course = course;
			this.isOpen = true;
		},

		async saveCourse() {
			try {
				this.isLoading = true;
				const batch = db.batch();
				const correctAnswers = this.course.answers.filter(
					(answer) => answer.isValid
				).length;
				const points = correctAnswers;

				const now = Date.now();

				const data = {
					isCompleted: true,
					isReviewed: true,
					reviewedAt: now,
					isValid: correctAnswers == this.course.answers.length,
					correctAnswers,
					points,
					answers: this.course.answers,
				};

				batch.update(db.collection("certifications").doc(this.course.doc), {
					...data,
				});

				if (points > 0) {
					batch.set(db.collection("points").doc(), {
						category: "Mars Academy",
						period: this.course.period,
						year: this.course.year,
						ref: `Mars Academy curso periodo ${this.course.period} ${this.course.year}`,
						timeStamp: now,
						points,
						uid: this.course.uid,
					});

					batch.update(db.collection("users").doc(this.course.uid), {
						points: firebase.firestore.FieldValue.increment(points),
						totalPoints: firebase.firestore.FieldValue.increment(points),
					});
				}

				await batch.commit();
				this.courses = this.courses.filter(
					(course) => course.doc != this.course.doc
				);
				this.isOpen = false;
			} catch (error) {
				console.log(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style>
.modal {
	width: 100dvw;
	height: 100dvh;
	background: #00000080;
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.max-w-screen {
	max-width: 850px !important;
}

.max-w-screen-modal {
	max-width: 700px !important;
}

.border-1 {
	border: 2px solid rgba(0, 0, 0, 0.292) !important;
	border-radius: 0.5rem;
}
</style>
